<template>
  <div :id="!!id.length ? id : null" class="location-listing row">
    <div class="col-12 np--all location-listing__title--wrapper">
      <h2
          ref="title"
          class="location-listing__title h5 text-center push-v--desktop--small push-v--tablet--x-small push-v--mobile--x-small"
      >
        {{ title }}
      </h2>
    </div>

    <div ref="cards" class="location-listing--container container col-10">
      <div class="row justify-content-center align-items-center">
        <div
            v-for="(item, index) in items"
            :key="index"
            class="location-listing__col col-lg-4 np--all push-v--desktop--small"
        >
          <div class="location-listing__block">
            <div class="location-listing__icons">
              <location-card :images="item.images" />
            </div>

            <section class="location-listing__info">
              <div class="wrapper-column">
                <h5 class="location-listing__info--title">{{ item.title }}</h5>
                <p class="location-listing__info--subtitle">{{ item.subTitle }},&nbsp;{{ item.label }}</p>
              </div>
              <div class="location-listing__info--tube-directions">
                <div class="wrapper">
                  <nuxt-link
                      v-if="callToActionText"
                      :to="item.link.slug"
                      class="location-listing__info--cta"
                      aria-label="explore-location"
                      @click.native="gatherData(index)"
                  >
                    {{ callToActionText }}<ArrowIcon />
                  </nuxt-link>
                </div>
                <v-popover v-if="item.nearestStation" placement="top">
                  <div class="location-listing__info--tube-sign" />
                  <template slot="popover">
                    <div v-html="item.nearestStation" />
                  </template>
                </v-popover>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clickedCta } from '~/utils/gtm-events.js';
import ArrowIcon from '~/assets/icons/chevron-white.svg?inline';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { gsap, CSSPlugin } from 'gsap';
import LocationCard from '~/components/components/LocationCard.vue';

export default {
  components: {
    ArrowIcon,
    LocationCard,
  },
  props: {
    id: { type: String, default: '' },
    title: { type: String, default: 'Locations', required: true },
    callToActionText: { type: String, default: 'Explore', required: true },
    intro: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(CSSPlugin);
    const { cards, title } = this.$refs;

    gsap.to(cards, {
      y: -230,
      duration: 3,
      ease: 'none',
      scrollTrigger: {
        trigger: cards,
        scrub: true,
      },
    });

    gsap.to(title, {
      css: { scale: 1.15 },
      duration: 3,
      ease: 'none',
      scrollTrigger: {
        trigger: cards,
        scrub: true,
      },
    });
  },
  methods: {
    gatherData(index) {
      clickedCta(this.$gtm, this.callToActionText, this.items[index].link.title, null, this.items[index].link.slug);
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/modules/_location-listing.scss';
</style>
