<template>
  <div class="locations_slider">
    <div ref="slider" v-swiper:mySwiper="swiperOption" class="simple-slider">
      <div class="swiper-wrapper">
        <div v-for="(image, index) in images" :key="index" class="swiper-slide simple-slider__item">
          <KenticoResponsiveImage
              :alt="image.description"
              :src="image.url"
              class="simple-slider__image"
              :sizes="imageSizes"
          />
        </div>
      </div>
      <div slot="button-next" class="swiper-button swiper-button-next"><Chevron /></div>
      <div slot="button-prev" class="swiper-button swiper-button-prev"><Chevron /></div>
      <div class="locations_slider__control">
        <ul class="locations_slider__progress">
          <li
              v-for="(dot, index) in images"
              :key="index"
              :class="{ active: currentSection == index + 1 }"
              class="locations_slider__progress-item"
          >
            <button class="locations_slider__progress-dot">Go to slide {{ index + 1 }}</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import getStatic from '~/utils/get-static';
// import { BREAKPOINTS, BREAKPOINT_SM } from '~/utils/constants';
import pascalCase from 'pascal-case';
import { viewedCarousel } from '~/utils/gtm-events.js';

export default {
  props: {
    images: {
      type: Array,
      required: true,
      default() {
        return [
          {
            url: getStatic('/images/content/what-we-do/people-cycling.png'),
            description: 'group of people cycling',
          },
        ];
      },
    },
  },
  data() {
    return {
      currentSection: 1,
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        keyboard: {
          enabled: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          slideChange: () => {
            this.currentSection = this.$refs.slider ? this.$refs.slider.swiper.realIndex + 1 : 1;

            // if slide has a name, use that, otherwise just use 'image{index}' as name
            const imageTitle = this.images[this.currentSection - 1].name ? this.images[this.currentSection - 1].name : `image${this.currentSection}`;
            const pascalRoom = pascalCase(this.room && this.room.toLowerCase());
            viewedCarousel(this.$gtm, pascalRoom, imageTitle, this.currentSection, this.images.length );
          },
        },
      },
      imageSizes: {
        default: [900, 750],
      },
    };
  },
  methods: {
    goToSection(section) {
      this.mySwiper.slideTo(section);
    },
  },
};
</script>

<style lang="css">
@import '~/node_modules/swiper/dist/css/swiper.css';
/* @import '~/assets/scss/atomic/modules/_location_card.scss'; */
</style>
